<template>
  <div class="m-4 header">
    <img class="mx-4" width="150" height="100" alt="Diablo II: Resurrected" src="../assets/logo.png">
    <h1 class="header-text">{{ title }}</h1>
    <b-dropdown
      variant="outline-light"
      class="m-4"
    >
      <template #button-content>
        <b-icon icon="globe" aria-hidden="true"></b-icon>
        EN / 文
      </template>
      <b-dropdown-item @click="changeLanguage('en-us')">English</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('fr')">Français</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('zh-cn')">简体中文</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('zh-tw')">繁體中文</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('ko')">한국어</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('es')">Español</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('de')">Deutsch</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('it')">Italiano</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('pt-br')">Português</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('ja')">日本語</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('ru')">Русский</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('pl')">Polski</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'TerrorZoneHeader',

  props: {
    language: {
      type: String,
      required: true
    },
  },

  emits: ['change-language'],
  
  computed: {
    title () {
      // TODO: use i18n to handle differe language display
      switch(this.language) {
        case 'fr':
          return 'Diablo II histoire et prévisions de la zone terroriste';
        case 'zh-cn':
          return '暗黑II恐怖区域历史和预测';
        case 'zh-tw':
          return '暗黑II恐懼區域歷史和預測';
        case 'ko':
          return '디아블로 II 테러존 이력 및 예측';
        case 'de':
          return 'Diablo II Geschichte und Prognose der Terrorzone';
        case 'es':
          return 'Diablo II historio kaj prognozo de terorzono';
        case 'ru':
          return 'Diablo II история и прогноз террористической зоны';
        case 'ja':
          return 'ディアブロII恐怖領域の歴史と予測';
        case 'it':
          return 'Diablo II Storia e previsioni delle zone terroristiche';
        case 'pt-br':
          return 'Diablo II História e previsão das zonas de terror';
        case 'pl':
          return 'Diablo II Historia i prognoza Obszary Grozy';
        default:
          return 'Diablo II Terror Zones history and forecast';
      }
    },
  },

  methods: {
    changeLanguage (language) {
      console.log('change language to: ' + language);
      localStorage.setItem('language', language);
      this.$emit('change-language', language);
    },
  }
}
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 600px) {
  .header {
    display: inline-block;
    justify-content: center;
  }
}
.header-text {
  margin-top: auto;
  margin-bottom: auto;
  color: #ddd;
}
</style>
