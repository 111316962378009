export const supportedLanguages = ['en-us', 'fr', 'zh-cn', 'zh-tw', 'ko', 'de', 'es', 'ru', 'ja', 'it', 'pl', 'pt-br'];

export const tzData = [
  {
    'id': '1-1',
    'act': 1,
    'name': {
      'en-us': 'Blood Moor and Den of Evil',
      'fr': 'La Lande sanglante et le Repaire du Mal',
      'zh-cn': '鲜血荒地、 邪恶洞窟',
      'zh-tw': '鮮血荒地、 邪惡洞窟',
      'ko': '핏빛 황무지와 악의 소굴',
      'de': 'Blutmoor und Höhle des Bösen',
      'es': 'Landa de Sangre y Cubil del Mal',
      'ru': 'Кровавая пустошь и Логово зла',
      'ja': '血の荒野と邪悪の巣窟',
      'it': 'Lande Insanguinate e Abisso del Male',
      'pl': 'Krwawe Wrzosowisko i Siedlisko Zła',
      'pt-br': 'Charco Sangrento e Covil do Mal'
    }
  },
  {
    'id': '1-2',
    'act': 1,
    'name': {
      'en-us': 'Cold Plains and The Cave',
      'fr': 'Les Plaines gelées et la Grotte',
      'zh-cn': '冰冷之原、洞穴',
      'zh-tw': '冰冷之原、洞穴',
      'ko': '차가운 평야와 동굴',
      'de': 'Kalte Ebene und die Höhle',
      'es': 'Llanuras Frías y Cueva',
      'ru': 'Стылые равнины и пещера',
      'ja': '荒涼の平原と洞窟',
      'it': 'Pianure Gelate e la Caverna',
      'pl': 'Zimna Równina i Jaskinia',
      'pt-br': 'Planície Gélida e Caverna'
    }
  },
  {
    'id': '1-3',
    'act': 1,
    'name': {
      'en-us': 'Burial Grounds, The Crypt, and The Mausoleum',
      'fr': 'Le Cimetière, la Crypte et le Mausolée',
      'zh-cn': '埋骨之地、墓穴、大陵墓',
      'zh-tw': '埋骨之地、墓穴、大陵墓',
      'ko': '매장지, 묘실, 영묘',
      'de': 'Friedhof, Krypta und Mausoleum',
      'es': 'Cementerio, Cripta y Mausoleo',
      'ru': 'Погост, склеп и мавзолей',
      'ja': '埋葬地、墓所、霊廟',
      'it': 'Cimitero, la Cripta e il Mausoleo',
      'pl': 'Cmentarzysko, Krypta oraz Mauzoleum',
      'pt-br': 'Cemitério, Cripta e Mausoléu'
    }
  },
  {
    'id': '1-4',
    'act': 1,
    'name': {
      'en-us': 'Stony Field',
      'fr': 'Le Champ de pierres',
      'zh-cn': '乱石旷野',
      'zh-tw': '亂石曠野',
      'ko': '바위 벌판',
      'de': 'Feld der Steine',
      'es': 'Campo Pedregoso',
      'ru': 'Каменистое поле',
      'ja': '石の原野',
      'it': 'Campo di Pietra',
      'pl': 'Kamienne Pole',
      'pt-br': 'Campo Rochoso'
    }
  },
  {
    'id': '1-5',
    'act': 1,
    'name': {
      'en-us': 'Tristram',
      'fr': 'Tristram',
      'zh-cn': '崔斯特姆',
      'zh-tw': '崔斯特姆',
      'ko': '트리스트럼',
      'de': 'Tristram',
      'es': 'Tristán',
      'ru': 'Тристрам',
      'ja': 'トリストラム',
      'it': 'Tristram',
      'pl': 'Tristram',
      'pt-br': 'Tristram'
    }
  },
  {
    'id': '1-6',
    'act': 1,
    'name': {
      'en-us': 'Dark Wood and Underground Passage',
      'fr': 'Le Bois obscur, le Passage souterrain',
      'zh-cn': '黑暗森林、地底通道',
      'zh-tw': '黑暗森林、地底通道',
      'ko': '어둠숲, 지하 통로',
      'de': 'Dunkelwald, Unterirdische Durchgang',
      'es': 'Bosque Oscuro, Pasadizo Subterráneo',
      'ru': 'Темнолесье и подземного прохода',
      'ja': '常闇の森と地下道',
      'it': 'Foresta Oscura e Passaggio Sotterraneo',
      'pl': 'Mroczna Knieja i Podziemnego Tunelu',
      'pt-br': 'Floresta Sombria, Passagem Subterrânea'
    }
  },
  {
    'id': '1-7',
    'act': 1,
    'name': {
      'en-us': 'Black Marsh and The Hole',
      'fr': 'Le Marais sombre, le Gouffre',
      'zh-cn': '黑色荒地、 地洞',
      'zh-tw': '黑色荒地、 地洞',
      'ko': '검은 습지, 구렁',
      'de': 'Schwarzmoor, Loch',
      'es': 'Pantano Negro, Hoyo',
      'ru': 'Черная топь, норы',
      'ja': '黒の湿原と洞穴',
      'it': 'Palude Nera e  il Buco',
      'pl': 'Czarne Moczary i Jama',
      'pt-br': 'Charco Tenebroso, Buraco'
    }
  },
  {
    'id': '1-8',
    'act': 1,
    'name': {
      'en-us': 'The Forgotten Tower',
      'fr': 'La Tour oubliée',
      'zh-cn': '遗忘之塔',
      'zh-tw': '遺忘之塔',
      'ko': '잊힌 탑',
      'de': 'Der Vergessene Turm',
      'es': 'Torre Olvidada',
      'ru': 'Забытая башня',
      'ja': '忘れられし塔',
      'it': 'La Torre Dimenticata',
      'pl': 'Zapomniana Wieża',
      'pt-br': 'A Torre Esquecida'
    }
  },
  {
    'id': '1-9',
    'act': 1,
    'name': {
      'en-us': 'The Pit',
      'fr': 'La Fosse',
      'zh-cn': '地穴',
      'zh-tw': '地穴',
      'ko': '구덩이',
      'de': 'Die Grube',
      'es': 'El Foso',
      'ru': 'Яма',
      'ja': '穴ぐら',
      'it': "L'Abisso",
      'pl': 'Czeluść',
      'pt-br': 'O Fosso'
    }
  },
  {
    'id': '1-10',
    'act': 1,
    'name': {
      'en-us': 'Jail and Barracks',
      'fr': 'La Prison, la Caserne',
      'zh-cn': '监牢、兵营',
      'zh-tw': '監牢、兵營',
      'ko': '감옥과 병영',
      'de': 'Gefängnis, la Caserne',
      'es': 'Cárcel, los Barracones',
      'ru': 'Тюрьма, Казармы',
      'ja': '牢獄と兵舎',
      'it': 'Prigione e Caserma',
      'pl': 'Więzienie i Koszar',
      'pt-br': 'Prisão, Quartel'
    }
  },
  {
    'id': '1-11',
    'act': 1,
    'name': {
      'en-us': 'Cathedral and Catacombs',
      'fr': 'La Cathédrale et les Catacombes',
      'zh-cn': '大教堂、地下墓穴',
      'zh-tw': '大教堂、地下墓穴',
      'ko': '대성당과 지하 묘지',
      'de': 'Kathedrale und Katakomben',
      'es': 'Catedral y Catacumbas',
      'ru': 'Собор и катакомбы',
      'ja': '大聖堂と地下墓地',
      'it': 'Cattedrale e Catacombe',
      'pl': 'Katedra i Katakumby',
      'pt-br': 'Catedral e Catacumbas'
    }
  },
  {
    'id': '1-12',
    'act': 1,
    'name': {
      'en-us': 'Moo Moo Farm',
      'fr': 'Ferme Meuh-Meuh',
      'zh-cn': '哞哞农场',
      'zh-tw': '哞哞農場',
      'ko': '음메음메 농장',
      'de': 'Muh-Muh-Farm',
      'es': 'Granja Mu Mu',
      'ru': 'Ферма «Му-му»',
      'ja': 'モーモー牧場',
      'it': 'Fattoria Muu Muu',
      'pl': 'Farma Muu Muu',
      'pt-br': 'Fazenda Mu-mu'
    }
  },
  {
    'id': '2-1',
    'act': 2,
    'name': {
      'en-us': 'Lut Gholein Sewers',
      'fr': 'Égouts de Lut Gholein',
      'zh-cn': '鲁高因下水道',
      'zh-tw': '鲁高因下水道',
      'ko': '루트 골레인 하수도',
      'de': 'Kanalisation von Lut Gholein',
      'es': 'Cloacas de Lut Gholein',
      'ru': 'Стоки Лут Голейна',
      'ja': 'ラット・ゴーレインの下水道',
      'it': 'Fogne di Lut Gholein',
      'pl': 'Kanały pod Lut Gholein',
      'pt-br': 'Esgoto de Lut Gholein'
    }
  },
  {
    'id': '2-2',
    'act': 2,
    'name': {
      'en-us': 'Rocky Waste and Stony Tomb',
      'fr': 'Le Désert de roche et le Tombeau de pierre',
      'zh-cn': '碎石荒地、古老石墓',
      'zh-tw': '碎石荒地、古老石墓',
      'ko': '바위투성이 황무지와 바위 무덤',
      'de': 'Felsige Öde und Steingrab',
      'es': 'Erial Rocoso y Tumba Pedregosa',
      'ru': 'Каменистая пустошь и каменная гробница',
      'ja': '岩だらけの荒れ地と石造りの墓所',
      'it': 'Landa Rocciosa e Tomba di Pietra',
      'pl': 'Skalne Pustkowie i Kamienny Grobowiec',
      'pt-br': 'Ermo Rochoso e Tumba Pétrea'
    }
  },
  {
    'id': '2-3',
    'act': 2,
    'name': {
      'en-us': 'Dry Hills and Halls of the Dead',
      'fr': 'Les Collines arides et les Couloirs des morts',
      'zh-cn': '干土高地、死亡之殿',
      'zh-tw': '乾土高地、死亡之殿',
      'ko': '메마른 언덕과 망자의 전당',
      'de': 'Verdorrte Hügel und Hallen der Toten',
      'es': 'Colinas Áridas y Salas de la Muerte',
      'ru': 'Иссохшие холмы и Чертоги Мертвых',
      'ja': '乾きの丘と亡者の広間',
      'it': 'Colline Aride e Sale dei Morti',
      'pl': 'Suche Wzgórza i Sale Umarłych',
      'pt-br': 'Colinas Áridas e Salões dos Mortos'
    }
  },
  {
    'id': '2-4',
    'act': 2,
    'name': {
      'en-us': 'Far Oasis',
      'fr': 'L’Oasis lointaine',
      'zh-cn': '遥远的绿洲',
      'zh-tw': '遙遠的綠洲',
      'ko': '머나먼 오아시스',
      'de': 'Ferne Oase',
      'es': 'Oasis Lejano',
      'ru': 'Дальний оазис',
      'ja': '遥かなるオアシス',
      'it': 'Oasi Lontana',
      'pl': 'Daleka Oaza',
      'pt-br': 'Oásis Longínquo'
    }
  },
  {
    'id': '2-5',
    'act': 2,
    'name': {
      'en-us': 'Ancient Tunnels',
      'fr': 'Les Tunnels antiques',
      'zh-cn': '古代通道',
      'zh-tw': '古代通道',
      'ko': '고대 토굴',
      'de': 'Alte Tunnels',
      'es': 'Túneles Antiguos',
      'ru': 'Древние туннели',
      'ja': '古の坑道',
      'it': 'Antichi Tunnel',
      'pl': 'Prastare Tunele',
      'pt-br': 'Túneis Ancestrais'
    }
  },
  {
    'id': '2-6',
    'act': 2,
    'name': {
      'en-us': 'Lost City, Valley of Snakes, and Claw Viper Temple',
      'fr': 'La Ville oubliée, la Vallée des serpents et le Temple des Vipères',
      'zh-cn': '失落古城、群蛇峡谷、利爪蛇魔神殿',
      'zh-tw': '失落古城、群蛇峽谷、利爪蛇魔神殿',
      'ko': '잊힌 도시, 뱀의 골짜기, 발톱 독사 사원',
      'de': 'Vergessene Stadt, Tal der Schlangen und Tempel der Klauenvipern',
      'es': 'Ciudad Perdida, Valle de las Serpientes y Templo de la Garra Viperina',
      'ru': 'Затерянный город, Долина змей и храм Когтистого Змея',
      'ja': '失われし都、蛇の谷、クロウ・ヴァイパーの寺院',
      'it': 'Città Perduta, Valle dei Serpenti e Tempio delle Vipere',
      'pl': 'Zaginione Miasto, Dolina Węży i Świątynia Żmijoszponów',
      'pt-br': 'Cidade Perdida, Vale das Cobras e Templo da Víbora da Garra'
    }
  },
  {
    'id': '2-7',
    'act': 2,
    'name': {
      'en-us': 'Arcane Sanctuary',
      'fr': 'Le Sanctuaire des Arcanes',
      'zh-cn': '秘法圣殿',
      'zh-tw': '秘法聖殿',
      'ko': '비전의 성역',
      'de': 'Geheime Zuflucht',
      'es': 'Santuario Arcano',
      'ru': 'Магическое убежище',
      'ja': '深遠なる聖域',
      'it': 'Santuario Arcano',
      'pl': 'Tajemne Sanktuarium',
      'pt-br': 'Santuário Arcano'
    }
  },
  {
    'id': '2-8',
    'act': 2,
    'name': {
      'en-us': "Tal Rasha's Tombs and Tal Rasha's Chamber",
      'fr': "Les Tombeaux de Tal Rasha, La Chambre de Tal Rasha",
      'zh-cn': '塔拉夏的古墓、塔拉夏的密室',
      'zh-tw': "塔拉夏的古墓、塔拉夏的密室",
      'ko': "탈 라샤의 무덤, 탈 라샤의 방",
      'de': "Tal Rashas Grab, Tal Rashas Kammer",
      'es': "Tumba de Tal Rasha, Cámara de Tal Rasha",
      'ru': "Склепы Тал Раши, Погребальный зал Тал Раши",
      'ja': "タル・ラシャの墓、タル・ラシャの间",
      'it': 'Tomba di Tal Rasha e Cripta di Tal Rasha',
      'pl': 'Grobowiec Tal Rashy i Komnata Tal Rashy',
      'pt-br': 'Tumbas de Tal Rasha, Câmara de Tal Rasha'
    }
  },
  {
    'id': '3-1',
    'act': 3,
    'name': {
      'en-us': 'Spider Forest and Spider Cavern',
      'fr': 'La Forêt de l’Araignée et la Caverne de l’Araignée',
      'zh-cn': '蜘蛛森林、蜘蛛洞窟',
      'zh-tw': '蜘蛛森林、蜘蛛洞窟',
      'ko': '거미 숲과 거미 동굴',
      'de': 'Spinnenwald und Spinnenhöhle',
      'es': 'Bosque de Arañas y Caverna de Arañas',
      'ru': 'Паучий лес и паучья пещера',
      'ja': '蜘蛛の森と蜘蛛の洞窟',
      'it': 'Foresta del Ragno e Caverna del Ragno',
      'pl': 'Las Pająków i Grota Pająków',
      'pt-br': 'Floresta Aracnídea e Caverna Aracnídea'
    }
  },
  {
    'id': '3-2',
    'act': 3,
    'name': {
      'en-us': 'Flayer Jungle and Flayer Dungeon',
      'fr': 'La Jungle de l’Écorcheur et la Prison de l’Écorcheur',
      'zh-cn': '剥皮丛林、剥皮地牢',
      'zh-tw': '剝皮叢林、剝皮地牢',
      'ko': '약탈자 밀림과 약탈자 던전',
      'de': 'Schinderdschungel und Schinderdungeon',
      'es': 'Selva Desolladora y Mazmorra de Desollamiento',
      'ru': 'Джунгли свежевателей и подземелье свежевателей',
      'ja': 'フレイヤーの密林とフレイヤーの地下牢',
      'it': 'Giungla Scorticatrice e Sotterraneo Scorticatore',
      'pl': 'Dżungla Łupieżców i Podziemia Łupieżców',
      'pt-br': 'Selva dos Esfoladores e Masmorra dos Esfoladores'
    }
  },
  {
    'id': '3-3',
    'act': 3,
    'name': {
      'en-us': 'Great Marsh',
      'fr': 'Le Grand marais',
      'zh-cn': '大沼泽',
      'zh-tw': '大沼澤',
      'ko': '거대 습지',
      'de': 'Großes Moor',
      'es': 'Gran Pantano',
      'ru': 'Болотина',
      'ja': '大湿原',
      'it': 'Grande Palude',
      'pl': 'Wielkie Moczary',
      'pt-br': 'Grande Charco'
    }
  },
  {
    'id': '3-4',
    'act': 3,
    'name': {
      'en-us': 'Kurast Bazaar, Ruined Temple, and Disused Fane',
      'fr': 'Le Bazar de Kurast, le Temple en ruine et le Sanctuaire abandonné',
      'zh-cn': '库拉斯特市集、荒废的神殿、废弃的寺院',
      'zh-tw': '庫拉斯特市集、荒廢的神殿、廢棄的寺院',
      'ko': '쿠라스트 시장, 허물어진 사원, 버려진 교회당',
      'de': 'Basar von Kurast, Zerfallener Tempel und Verlassenes Heiligtum',
      'es': 'Bazar de Kurast, Templo en Ruinas y Fano Abandonado',
      'ru': 'Курастский базар, разрушенный храм и заброшенная церковь',
      'ja': 'クラスト・バザール、荒れ果てた寺院、打ち捨てられし神殿',
      'it': 'Bazar di Kurast, Tempio in Rovina e Tempio Abbandonato',
      'pl': 'Bazar Kurast, Zrujnowana Świątynia i Opuszczona Kaplica',
      'pt-br': 'Bazar de Kurast, Templo Arruinado e Ádito Abandonado'
    }
  },
  {
    'id': '3-5',
    'act': 3,
    'name': {
      'en-us': 'Travincal',
      'fr': 'Travincal',
      'zh-cn': '崔凡克',
      'zh-tw': '崔凡克',
      'ko': '트라빈칼',
      'de': 'Travincal',
      'es': 'Travincal',
      'ru': 'Травинкаль',
      'ja': 'トラヴィンカル',
      'it': 'Travincal',
      'pl': 'Travincal',
      'pt-br': 'Travincal'
    }
  },
  {
    'id': '3-6',
    'act': 3,
    'name': {
      'en-us': 'Durance of Hate',
      'fr': 'La Prison de la Haine',
      'zh-cn': '憎恨的囚牢',
      'zh-tw': '憎恨的囚牢',
      'ko': '증오의 억류지',
      'de': 'Kerker des Hasses',
      'es': 'Represión del Odio',
      'ru': 'Узилище ненависти',
      'ja': '憎悪の檻',
      'it': "Prigione dell'Odio",
      'pl': 'Kazamaty Nienawiści',
      'pt-br': 'Cárcere do Ódio'
    }
  },
  {
    'id': '4-1',
    'act': 4,
    'name': {
      'en-us': 'Outer Steppes and Plains of Despair',
      'fr': 'Les Steppes infinies et les Plaines du désespoir',
      'zh-cn': '外围荒原、绝望平原',
      'zh-tw': '外圍荒原、絕望平原',
      'ko': '평원 외곽과 절망의 평원',
      'de': 'Äußere Steppe und Ebene der Verzweiflung',
      'es': 'Estepas Exteriores y Llanuras del Desaliento',
      'ru': 'Рубежные степи и Долина Отчаяния',
      'ja': '果ての草原と絶望の平原',
      'it': 'Steppe Esterne e Pianure della Disperazione',
      'pl': 'Zewnętrzne Stepy i Równina Rozpaczy',
      'pt-br': 'Estepes Externas e Planície do Desespero'
    }
  },
  {
    'id': '4-2',
    'act': 4,
    'name': {
      'en-us': 'River of Flame and City of the Damned',
      'fr': 'La Rivière de feu, la Ville des Damnés',
      'zh-cn': '火焰之河、罪罚之城',
      'zh-tw': '火焰之河、罪罰之城',
      'ko': '불길의 강과 저주받은 운명의 도시',
      'de': 'Flammenfluss und Stadt der Verdammten',
      'es': 'Río de Llamas y Ciudad de los Malditos',
      'ru': 'Огненная река и город Проклятых',
      'ja': '炎の河と呪われし街',
      'it': 'Fiume Infuocato e Città dei Dannati',
      'pl': 'Rzeka Płomieni i Miasto Potępionych',
      'pt-br': 'Rio de Chamas, Cidade dos Condenados'
    }
  },
  {
    'id': '4-3',
    'act': 4,
    'name': {
      'en-us': 'Chaos Sanctuary',
      'fr': 'Le Sanctuaire du chaos',
      'zh-cn': '混沌避难所',
      'zh-tw': '混沌庇難所',
      'ko': '혼돈의 성역',
      'de': 'Chaossanktuarium',
      'es': 'Santuario del Caos',
      'ru': 'Святилище Хаоса',
      'ja': '混沌の聖域',
      'it': 'Santuario del Caos',
      'pl': 'Sanktuarium Chaosu',
      'pt-br': 'Santuário do Caos'
    }
  },
  {
    'id': '5-1',
    'act': 5,
    'name': {
      'en-us': 'Bloody Foothills, Frigid Highlands and Abaddon',
      'fr': 'Les Collines Sanglantes, Les Hautes-Glaces, Abaddon',
      'zh-cn': '血腥丘陵、冰冻高地、亚巴顿',
      'zh-tw': '血腥丘陵、冰凍高地、亞巴頓',
      'ko': '핏빛 언덕, 혹한의 고산지와 나락',
      'de': 'Blutiges Vorgebirge, Eishochland und Abaddon',
      'es': 'Estribaciones Sangrientas, Tierras Altas Glaciales y Abaddon',
      'ru': 'Кровавые предгорья, Мерзлое нагорье и Аваддон',
      'ja': '血に塗れし丘陵、極寒の高原、奈落',
      'it': 'Colline Insanguinate, Altopiani del Gelo e Abaddon',
      'pl': 'Krwawe Wzgórza, Zlodowaciała Wyżyna i Abaddon',
      'pt-br': 'Colinas Sangrentas, Terras Altas Gélidas, Abadom'
    }
  },
  {
    'id': '5-2',
    'act': 5,
    'name': {
      'en-us': 'Glacial Trail and Drifter Cavern',
      'fr': 'La Piste des Glaces, La Caverne du Yéti',
      'zh-cn': '冰河小径、漂泊者洞窟',
      'zh-tw': '冰河小径、漂泊者洞窟',
      'ko': '빙하의 길과 부랑자의 동굴',
      'de': 'Gletscherweg und Flockenhöhle',
      'es': 'Senda Glacial y Caverna del Errante',
      'ru': 'Ледовый ход и Пещера скитальцев',
      'ja': '氷の道、流れ者の洞窟',
      'it': 'Sentiero Ghiacciato e Caverna dei Viandanti',
      'pl': 'Lodowy Trakt i Grota Tułaczy',
      'pt-br': 'Trilha Glacial, Caverna do Errante'
    }
  },
  {
    'id': '5-3',
    'act': 5,
    'name': {
      'en-us': "Ancient's Way and Icy Cellar",
      'fr': "Le Chemin des Anciens et la Cave Glaciale",
      'zh-cn': '先祖之路、冰窖',
      'zh-tw': "先祖之路、冰窖",
      'ko': "고대인의 길과 얼음 지하실",
      'de': "Weg der Urahnen und Eiskeller",
      'es': "Camino de los Antiguos y Sótano Helado",
      'ru': "Путь Древних и Ледяной грот",
      'ja': "古の道と氷の穴ぐら",
      'it': 'Via degli Antichi e Sotterraneo di Ghiaccio',
      'pl': 'Droga Starożytnych i Lodowa Piwnica',
      'pt-br': 'Caminho dos Ancestrais e Porão Gélido'
    }
  },
  {
    'id': '5-4',
    'act': 5,
    'name': {
      'en-us': 'Arreat Plateau and Pit of Acheron',
      'fr': 'Le Mont Arreat, Puits d’Acheron',
      'zh-cn': '亚瑞特高原、冥河地穴',
      'zh-tw': '亞瑞特高原、冥河地穴',
      'ko': '아리앗 고원과 아케론의 구덩이',
      'de': 'Arreathochebene, Grube von Acheron',
      'es': 'Meseta Arreat, Foso de Acheron',
      'ru': 'Арреатское плато и Пропасть Ахерона',
      'ja': 'アリート高地、冥穴',
      'it': "Plateau dell'Arreat, Abisso dell'Acheronte",
      'pl': 'Płaskowyż Arreat, Otchłań Acheronu',
      'pt-br': 'Platô de Arreat, Fosso de Aqueron'
    }
  },
  {
    'id': '5-5',
    'act': 5,
    'name': {
      'en-us': 'Crystalline Passage and Frozen River',
      'fr': 'Le Passage Cristallin et la Rivière Glacée',
      'zh-cn': '水晶通道、冰冻之河',
      'zh-tw': '水晶通道、冰凍之河',
      'ko': '수정 동굴과 얼어붙은 강',
      'de': 'Kristalldurchgang und Frostfluss',
      'es': 'Pasaje Cristalino y Río Helado',
      'ru': 'Ледяные пещеры и замерзшая река',
      'ja': '結晶道と凍てつく川',
      'it': 'Passaggio Cristallino e Fiume Gelido',
      'pl': 'Kryształowe Przejście i Zamarznięta Rzeka',
      'pt-br': 'Passagem Cristalina e Rio Congelado'
    }
  },
  {
    'id': '5-6',
    'act': 5,
    'name': {
      'en-us': "Nihlathak's Temple and Temple Halls",
      'fr': "Le Temple de Nihlathak, les Corridors de l’Angoisse, les Corridors de la Souffrance et les Corridors du Martyre",
      'zh-cn': '尼拉塞克神殿、怨恸之厅、苦痛之厅、沃特之厅',
      'zh-tw': "尼拉塞克神殿、怨慟之廳、苦痛之廳、沃特之廳",
      'ko': "니흘라탁의 사원, 고뇌의 전당, 고통의 전당, 보트의 전당",
      'de': "Nihlathaks Tempel, Hallen der Qual, Hallen der Schmerzen und Hallen von Vaught",
      'es': "Templo de Nihlathak, Salas de la Angustia, Salas del Dolor y Salas de Vaught",
      'ru': "Храм Нильятака, Чертоги Мучений, Чертоги Боли и Чертоги Воута",
      'ja': "ニーラタークの寺院、苦悶の広間、苦痛の広間、ヴォートの広間",
      'it': "Tempio di Nihlathak, Sale dell'Angoscia, Sale del Dolore e Sale di Vaught",
      'pl': 'Świątynia Nihlathaka, Sale Cierpienia, Sale Bólu i Sale Nieszczęścia',
      'pt-br': 'Templo de Nilatak, Salões da Angústia, Salões da Dor e Salões de Vaught'
    }
  },
  {
    'id': '5-7',
    'act': 5,
    'name': {
      'en-us': 'Worldstone Keep, Throne of Destruction, and Worldstone Chamber',
      'fr': 'Le Donjon de la Pierre-Monde, le Trône de la Destruction et la Chambre de la Pierre-Monde',
      'zh-cn': '世界之石要塞、毁灭王座、世界之石大殿',
      'zh-tw': '世界之石要塞、毀滅王座、世界之石大殿',
      'ko': '세계석 성채, 파괴의 왕좌, 세계석 보관실',
      'de': 'Weltsteinturm, Thron der Zerstörung und Kammer des Weltsteins',
      'es': 'Torre de la Piedra del Mundo, Trono de la Destrucción y Cámara de la Piedra del Mundo',
      'ru': 'Цитадель Мироздания, Престол Разрушения и Святилище Мироздания',
      'ja': 'ワールドストーン城塞、破壊の玉座、ワールドストーンの間',
      'it': 'Forte della Pietra del Mondo, Trono della Distruzione e Sale della Pietra del Mondo',
      'pl': 'Kamień Świata, Tron Zniszczenia i Komnata Kamienia Świata',
      'pt-br': 'Forte da Pedra do Mundo, Trono da Destruição e Câmara da Pedra do Mundo'
    }
  }
];