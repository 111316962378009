<template>
  <b-modal
    v-model="visible"
    title="Report Error"
    header-bg-variant="dark"
    header-text-variant="light"
    body-bg-variant="dark"
    body-text-variant="light"
    footer-bg-variant="dark"
    footer-text-variant="light"
    hideHeaderClose
    @ok="reportError"
  >
    <b-form-group
      id="report-email"
      label="Email address:"
      label-for="report-email-input"
      description="We'll never share your email with anyone else."
    >
      <b-form-input
        id="report-email-input"
        v-model="reportData.email"
        type="text"
        placeholder="Enter your contact"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      id="report-title"
      label="Error Type:"
      label-for="report-title-input"
      description="The topic you want to report."
    >
      <b-form-input
        id="report-title-input"
        v-model="reportData.title"
        type="text"
        placeholder="Enter error type"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      id="report-content"
      label="Error Detail:"
      label-for="report-content-input"
      description="Please descript the detail error"
    >
      <b-form-textarea
        id="report-content-input"
        v-model="reportData.content"
        type="text"
        rows="10"
        placeholder="Please descript the detail error..."
      ></b-form-textarea>
    </b-form-group>
  </b-modal>
</template>
<script>
import { contactUs } from '@/utils/api'

export default {
  name: 'ReportErrorModal',

  data() {
    return {
      visible: false,
      reportData: {},
    }
  },

  emits: ['success'],

  methods: {
    show () {
      this.visible = true;
    },

    hide () {
      this.visible = false;
    },

    reportError () {
      if (!this.reportData.email && !this.reportData.title && !this.reportData.content) {
        return;
      }
      const contact = this.reportData.email;
      const title = this.reportData.title;
      const type = 'Report error';
      const context = this.reportData.content;
      contactUs(contact, title, type, context);
      this.reportData = {};
      this.$emit('success');
      this.hide();
      // TODO: handle failed case.
    },
  },
}
</script>
