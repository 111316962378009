<template>
  <div id="app">
    <TerrorZoneHeader :language="language" @change-language="onLanguageChanged"/>
    <TerrorZoneQueryTable :language="language"/>
    <TerrorZoneFooter/>
  </div>
</template>

<script>
import { supportedLanguages } from '@/utils/tz_data'
import { parseParameters } from '@/utils/common'
import TerrorZoneHeader from './components/Header.vue'
import TerrorZoneFooter from './components/Footer.vue'
import TerrorZoneQueryTable from './components/TerrorZoneQueryTable.vue'

export default {
  name: 'App',

  components: {
    TerrorZoneHeader,
    TerrorZoneQueryTable,
    TerrorZoneFooter,
  },

  data() {
    return {
      language: null,
    }
  },

  created() {
    const paramStr = window.location.search;
    if (paramStr?.length > 0) {
      const params = parseParameters(paramStr);
      if (!!params['l'] && supportedLanguages.includes(params['l'])) {
        this.language = params['l'];
      }
      if (!!params['language'] && supportedLanguages.includes(params['language'])) {
        this.language = params['language'];
      }
    }
    if (!this.language) {
      this.language = localStorage.getItem('language') || 'en-us';
    }
  },

  methods: {
    onLanguageChanged (language) {
      if (supportedLanguages.includes(language)) {
        this.language = language;
      }
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  background-color: #000;
}

body {
  background-color: #000!important;
}
</style>
