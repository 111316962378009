export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function parseParameters(query) {
    if (query.startsWith('?')) {
        query = query.substr(1);
    }
    var vars = query.split("&");
    var queryString = {};
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        var key = decodeURIComponent(pair[0]);
        var value = decodeURIComponent(pair[1]);
        // If first entry with this name
        if (typeof queryString[key] === "undefined") {
            queryString[key] = decodeURIComponent(value);
            // If second entry with this name
        } else if (typeof queryString[key] === "string") {
            var arr = [queryString[key], decodeURIComponent(value)];
            queryString[key] = arr;
        } else {
            queryString[key].push(decodeURIComponent(value));
        }
    }
    return queryString
}

export function isParameterInQuery(queryString, parameterName) {
    if (!queryString) {
        return false
    }
    try {
        let params = parseParameters(queryString)
        if (parameterName instanceof Array) {
            for (let i = 0; i < parameterName.length; i++) {
                if (params[parameterName[i]]) {
                    return true
                }
            }
            return false
        } else {
            return !!params[parameterName]
        }
    } catch (error) {
        return false
    }
}