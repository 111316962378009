<template>
  <div class="mb-5 tz-table-container">
    <div v-if="loading">
      <b-spinner
        class="tz-table-loading"
        variant="light"
      />
      <p>
        (If there is no response for a long time, please clear the browser cache and force refresh the page) 
      </p>
    </div>
    <b-table
      v-else
      hover
      dark
      class="main-table"
      :fields="fields"
      :items="items"
    >
      <template #cell(zone)="data">
        <div v-for="(name, index) in data.value.nameList" :key='index'>
          {{ name[language] }}
          <b-badge
            v-if="data.value.showNowBadge"
            class="badge-pill badge-success"
          >
            Now
          </b-badge>
          <b-badge
            v-if="data.value.showComingBadge"
            class="badge-pill badge-danger"
          >
            Coming soon
          </b-badge>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { tzData } from '@/utils/tz_data'
import { getDataFromAllSites, getData, getRegionByUrl } from '@/utils/api'

export default {
  name: 'TerrorZoneQueryTable',

  data() {
    return {
      fields: [
        { key: 'time', thStyle: { width: "35%" } },
        { key: 'zone', label: 'Terror Zone', thStyle: { width: "65%" } },
      ],
      items: [],
      loading: false,
      maxTS: 0,
    }
  },

  props: {
    language: {
      type: String,
      required: true
    },
  },

  created() {
    this.loading = true;
    const region = this.getRegion();
    if (region) {
      getData(region)?.then(this.initTerrorZone);
    } else {
      getDataFromAllSites()?.forEach(resp => resp?.then(this.initTerrorZone));
    }
  },
  
  methods: {
    initTerrorZone (data) {
      if (!data) {
        console.log('Failed to get data.');
        return;
      }
      const isOldData = !data['data'].find(x => x['time'] * 1000 > this.maxTS);
      if (isOldData) {
        console.log('Skip update since already got the data.');
        return;
      }
      this.items = data['data'].map(this.dataRender).filter(x => x?.zone?.nameList?.length > 0);
      this.loading = false;
      let region = this.getRegion();
      if (!region) {
        region = getRegionByUrl(data['url']);
        localStorage.setItem('region', region);
        localStorage.setItem('fetch_all_ts', Date.now());
      }
    },

    dataRender (item) {
      let nameList = [];
      if (Array.isArray(item['zone'])) {
        for (let zoneId in item['zone']) {
          const tzItem = tzData.find(x => x['id'] === zoneId);
          if (!tzItem) {
            continue;
          }
          nameList.push(tzItem['name']);
        }
      } else {
        const tzItem = tzData.find(x => x['id'] === item['zone']);
        if (!tzItem) {
          return null;
        }
        nameList = [tzItem['name']];
      }
      const ts = parseInt(item['time'] / 60) * 60 * 1000;
      if (ts > this.maxTS) {
        this.maxTS = ts;
      }
      const timeNow = new Date();
      const timeTZ = new Date(ts);
      let showNowBadge = timeNow.getDate() === timeTZ.getDate() && timeNow.getHours() === timeTZ.getHours();
      const specialEventStart = new Date(1703962800 * 1000);
      const specialEventEnd = new Date(1704049200 * 1000);
      if (timeNow < specialEventEnd && timeTZ >= specialEventStart && timeTZ < specialEventEnd) {
        // Override badge for special event
        showNowBadge = true;
      }
      const showComingBadge = timeNow < timeTZ;
      return {
        'time': timeTZ.toLocaleString(),
        'zone': {
          'nameList': nameList,
          'showNowBadge': showNowBadge,
          'showComingBadge': showComingBadge,
        }
      };
    },

    getRegion() {
      const region = localStorage.getItem('region');
      const fetch_all_ts = localStorage.getItem('fetch_all_ts');
      if (!region || !fetch_all_ts) {
        return null;
      }
      const now = Date.now();
      if (now - fetch_all_ts > 30 * 24 * 60 * 60 * 1000) {
        localStorage.removeItem('region');
        localStorage.removeItem('fetch_all_ts');
        return null;
      }
      return region;
    }
  },
}
</script>

<style scoped>
.tz-table-loading {
  margin: 10vh;
}
.main-table {
  width: 75%;
  max-width: 900px;
  margin: auto;
}
@media (max-width: 600px) {
  .main-table {
    width: 90%;
  }
}
.table-cell {
  background-color: #777;
  color: #fff;
}
.badge-success {
    color: #fff;
    background-color: #28a745;
}
.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}
.badge-danger {
    color: #fff;
    background-color: #dc3545;
}
</style>
