<template>
  <b-sidebar
    title="Public Room"
    bg-variant="dark"
    text-variant="light"
    no-header
    shadow
    backdrop
    v-model="visible"
    @shown="fetchRooms"
  >
    <div class="px-3 py-2">
      <div v-if="hideAnnouncement">
        <b-button
          v-if="createdRooms.length > 0"
          pill
          variant="success"
          class="mt-5"
          @click="hideAnnouncement=false"
        >
          Announce My Room
        </b-button>
      </div>
      <div
        v-else
      >
        <h3>Announce My Room</h3>
        <b-form-group
          id="room-region"
          label="Server Region:"
          label-for="room-region-input"
          description="Server Region"
          class="left"
        >
          <b-form-select
            id="room-name-input"
            v-model="roomData.region"
            :options="regionOptions"
            class="w-100 option"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          id="room-ladder"
          label="Ladder:"
          label-for="room-ladder-input"
          description="Ladder"
          class="left"
        >
          <b-form-select
            id="room-name-input"
            v-model="roomData.ladder"
            :options="ladderOptions"
            class="w-100 option"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          id="room-platform"
          label="Platform:"
          label-for="room-platform-input"
          description="Platform"
          class="left"
        >
          <b-form-select
            id="room-name-input"
            v-model="roomData.platform"
            :options="platformOptions"
            class="w-100 option"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          id="room-mode"
          label="Mode:"
          label-for="room-mode-input"
          description="Mode"
          class="left"
        >
          <b-form-select
            id="room-name-input"
            v-model="roomData.mode"
            :options="modeOptions"
            class="w-100 option"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          id="room-name"
          label="Game Name:"
          label-for="room-name-input"
          description="Game Name that you want to announce"
          class="left"
        >
          <b-form-input
            id="room-name-input"
            v-model="roomData.name"
            type="text"
            placeholder="Please type room name..."
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="room-password"
          label="Password:"
          label-for="room-password-input"
          description="Game Password that you want to announce"
          class="left"
        >
          <b-form-input
            id="room-password-input"
            v-model="roomData.password"
            type="text"
            placeholder="Please type room password..."
          ></b-form-input>
        </b-form-group>
        <b-button variant="success" class="m-1" @click="announceRoom">Announce</b-button>
        <b-button class="m-1" @click="hideAnnouncement=true">Close</b-button>
      </div>
      <h3 class="mt-5">
        Public Room List
      </h3>
      <div class="m-3">
        <b-button
          title="Refresh"
          variant="outline-light"
          @click="fetchRooms"
        >
          <b-icon icon="arrow-counterclockwise" aria-hidden="true"></b-icon>
          Refresh
        </b-button>
      </div>
      <div
        v-if="createdRooms.length > 0"
      >
        <b-card
          v-for="(room, index) in createdRooms"
          :key="index"
          bg-variant="light"
          text-variant="black"
          class="text-center mt-2 px-0 py-0"
        >
          <b-card-header>{{ room.region }} {{ room.ladder }} {{ room.mode }} {{ room.platform }}</b-card-header>
          <b-card-text>Game: {{ room.name }} <span v-if="room.password">Password: {{ room.password }}</span></b-card-text>
        </b-card>
      </div>
      <div
        v-else
        class="text-center mt-3"
      >
        <p>
          There are no rooms published recently
        </p>
        <p>
          You can <b-button variant="success" class="m-1" @click="hideAnnouncement=false">Announce your room</b-button>
        </p>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import { getPublicRooms, announceRoom } from '@/utils/api'

export default {
  name: 'PublicRoom',

  data() {
    return {
      visible: false,
      roomData: {platform: "PC", mode: "Softcore"},
      createdRooms: [],
      hideAnnouncement: true,
      loading: false,
      regionOptions: [
          { value: 'Americas', text: 'Americas' },
          { value: 'Asia', text: 'Asia' },
          { value: 'Europe', text: 'Europe' }
      ],
      platformOptions: [
          { value: 'PC', text: 'PC' },
          { value: 'Playstation', text: 'Playstation' },
          { value: 'Switch', text: 'Switch' },
          { value: 'XBox', text: 'XBox' }
      ],
      modeOptions: [
          { value: 'Softcore', text: 'Softcore' },
          { value: 'Hardcore', text: 'Hardcore' }
      ],
      ladderOptions: [
          { value: 'Ladder', text: 'Ladder' },
          { value: 'Non-Ladder', text: 'Non-Ladder' }
      ],
    }
  },

  methods: {
    show () {
      this.visible = true;
    },

    hide () {
      this.visible = false;
    },

    announceRoom () {
      if (!this.roomData.region || !this.roomData.platform || !this.roomData.ladder || !this.roomData.mode || !this.roomData.name) {
        return;
      }
      announceRoom(this.roomData)?.then(this.fetchRooms);
      this.roomData.name = "";
      this.roomData.password = "";
      this.hideAnnouncement = true;
      // TODO: show submit success message.
    },

    fetchRooms () {
      this.loading = true;
      getPublicRooms()?.then(this.updateRooms);
    },

    updateRooms (data) {
      this.createdRooms = data['data'];
      this.loading = false;
    }
  },
}
</script>
<style scoped>
.left {
  text-align: left;
}
.option {
  height: 30px;
}
</style>