import axios from 'axios'

const devMode = false;
const DEFAULT_BASE_URL = devMode ? '' : 'https://api.d2tz.info';
const SITE_MAP = {'us': 'https://api.d2tz.info', 'asia': 'https://asia.d2tz.info', 'eu': 'https://eu.d2tz.info'}
const ALL_SITE_URLS = devMode ? [''] : Object.keys(SITE_MAP).map(key => SITE_MAP[key]);
const apiToken = '4N75YhH1wUBR5bhr';

function apiRequest(opt) {
  if (!opt) {
    return;
  }
  const options = {
    method: opt.method || 'get',
    url: opt.url,
    headers: {
      Authorization: apiToken
    }
  }
  if (options.method === 'get') {
    options.params = opt.params || {}
  } else if (options.method === 'post' || options.method === 'put' || options.method === 'delete') {
    options.data = opt.params || {}
  }
  return axios(options).then(res => {
    if (res && res.data) {
      res.data.url = opt.url
      return res.data
    } else {
      return res;
    }
  }).catch(error => {
    if (!error) {
      console.log('unknown error')
      return null
    }
    console.log(error)
    if (error.response && error.response.status && error.response.status >= 500) {
      console.log('Failed to process request. Internal Server Error for ' + opt.url)
    } else {
      throw error
    }
  })
}

export function getData(region=null) {
  const base_url = getBaseUrl(region);
  return apiRequest({
    url: `${base_url}/terror_zone`,
    method: 'get'
  });
}

export function getRegionByUrl(url) {
  return Object.keys(SITE_MAP).find(key => url.startsWith(SITE_MAP[key]));
}

export function getDataFromAllSites() {
  let result = [];
  for (const url of ALL_SITE_URLS) {
    result.push(apiRequest({url: `${url}/terror_zone`, method: 'get'}));
  }
  return result;
}

export function contactUs(contact, title, type, context, region=null) {
  const base_url = getBaseUrl(region);
  return apiRequest({
    url: `${base_url}/contact`,
    method: 'post',
    params: {
      "contact": contact,
      "title": title,
      "type": type,
      "context": context
    }
  });
}

export function getPublicRooms(region=null) {
  const base_url = getBaseUrl(region);
  return apiRequest({
    url: `${base_url}/public_room`,
    method: 'get'
  });
}

export function announceRoom(room, region=null) {
  const base_url = getBaseUrl(region);
  return apiRequest({
    url: `${base_url}/public_room`,
    method: 'post',
    params: {
      "region": room.region,
      "platform": room.platform,
      "ladder": room.ladder,
      "mode": room.mode,
      "name": room.name,
      "password": room.password
    }
  });
}

export function getBaseUrl(region=null) {
  if (!region) {
    return DEFAULT_BASE_URL;
  }
  return SITE_MAP[region] || DEFAULT_BASE_URL;
}