<template>
  <b-modal
    v-model="visible" 
    title="Report Next Terror Zone"
    header-bg-variant="dark"
    header-text-variant="light"
    body-bg-variant="dark"
    body-text-variant="light"
    footer-bg-variant="dark"
    footer-text-variant="light"
    hideHeaderClose
    @ok="report"
  >
    <b-form-group
      id="next-act"
      label="Game Act:"
      label-for="next-act-input"
      description="The act of next terror zone"
    >
      <b-form-select
        id="next-act-input"
        v-model="nextAct"
        :options="actOptions"
        class="w-100 option"
      ></b-form-select>
    </b-form-group>
    <b-form-group
      id="next-tz"
      label="Terror Zone:"
      label-for="next-tz-input"
      description="The terror zone name"
    >
      <b-form-select
        id="next-tz-input"
        v-model="nextTZ"
        :options="tzOptions"
        class="w-100 option"
      ></b-form-select>
    </b-form-group>
  </b-modal>
</template>

<script>
import { tzData } from '@/utils/tz_data'
import { contactUs } from '@/utils/api'

export default {
  name: 'ReportNextTerrorZoneModal',

  data() {
    return {
      visible: false,
      nextAct: null,
      nextTZ: null,
      actOptions: [
          { value: null, text: '(Please select act)' },
          { value: 1, text: 'Act 1' },
          { value: 2, text: 'Act 2' },
          { value: 3, text: 'Act 3' },
          { value: 4, text: 'Act 4' },
          { value: 5, text: 'Act 5' }
      ],
    }
  },

  emits: ['success'],

  computed: {
    tzOptions () {
      let options = [
        { value: null, text: '(Please select terror zone)' },
      ];
      if (!this.nextAct) {
        return options;
      }
      tzData.filter(x => x.act===this.nextAct).forEach(x => options.push({ value: x['id'], text: x['name']['en-us'] }));
      return options;
    },
  },

  methods: {
    show () {
      this.visible = true;
    },

    hide () {
      this.visible = false;
    },

    report () {
      if (!this.nextAct || !this.nextTZ) {
        return;
      }
      const contact = 'N/A';
      const title = 'Next: ' + this.nextTZ;
      const type = 'Next Terror Zone';
      const context = this.nextTZ;
      contactUs(contact, title, type, context);
      this.nextAct = null;
      this.nextTZ = null;
      this.$emit('success');
      this.hide();
      // TODO: handle failed case.
    },
  },
}
</script>
<style scoped>
.option {
  height: 30px;
}
</style>