<template>
  <b-modal
    v-model="visible" 
    title="Contact Us"
    header-bg-variant="dark"
    header-text-variant="light"
    body-bg-variant="dark"
    body-text-variant="light"
    footer-bg-variant="dark"
    footer-text-variant="light"
    hideHeaderClose
    @ok="contact"
  >
    <b-form-group
      id="contact-email"
      label="Email address:"
      label-for="contact-email-input"
      description="We'll never share your email with anyone else."
    >
      <b-form-input
        id="contact-email-input"
        v-model="contactData.email"
        type="text"
        placeholder="Enter your contact"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      id="contact-title"
      label="Title:"
      label-for="contact-title-input"
      description="The topic you want to contact."
    >
      <b-form-input
        id="contact-title-input"
        v-model="contactData.title"
        type="text"
        placeholder="Enter topic"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      id="contact-content"
      label="Content:"
      label-for="contact-content-input"
      description="The detail content you want to contact."
    >
      <b-form-textarea
        id="contact-content-input"
        v-model="contactData.content"
        type="text"
        rows="10"
        placeholder="Please type the detail context..."
      ></b-form-textarea>
    </b-form-group>
  </b-modal>
</template>

<script>
import { contactUs } from '@/utils/api'

export default {
  name: 'ContactUsModal',

  data() {
    return {
      visible: false,
      contactData: {},
    }
  },

  emits: ['success'],

  methods: {
    show () {
      this.visible = true;
    },

    hide () {
      this.visible = false;
    },

    contact () {
      if (!this.contactData.email && !this.contactData.title && !this.contactData.content) {
        return;
      }
      const contact = this.contactData.email;
      const title = this.contactData.title;
      const type = 'Contact us';
      const context = this.contactData.content;
      contactUs(contact, title, type, context);
      this.contactData = {};
      this.$emit('success');
      this.hide();
      // TODO: handle failed case.
    },
  },
}
</script>